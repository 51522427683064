var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-space",
        { attrs: { direction: "vertical" } },
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "CPU",
                        bordered: false,
                      },
                    },
                    [
                      _vm.server.cpu
                        ? _c(
                            "a-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-space",
                                    {
                                      attrs: {
                                        direction: "vertical",
                                        size: "large",
                                      },
                                    },
                                    [
                                      _c("a-statistic", {
                                        staticStyle: { "margin-right": "50px" },
                                        attrs: {
                                          title: "核心数",
                                          value: _vm.server.cpu.cpuNum,
                                          "value-style": { color: "#3f8600" },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prefix",
                                              fn: function () {
                                                return [
                                                  _c("a-icon", {
                                                    attrs: { type: "setting" },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3346964861
                                        ),
                                      }),
                                      _c("a-statistic", {
                                        staticStyle: { "margin-right": "50px" },
                                        attrs: {
                                          title: "用户使用率",
                                          value: _vm.server.cpu.used,
                                          precision: 2,
                                          suffix: "%",
                                          "value-style": { color: "#3f8600" },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prefix",
                                              fn: function () {
                                                return [
                                                  _c("a-icon", {
                                                    attrs: { type: "team" },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3016208662
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "a-space",
                                    {
                                      attrs: {
                                        direction: "vertical",
                                        size: "large",
                                      },
                                    },
                                    [
                                      _c("a-statistic", {
                                        staticStyle: { "margin-right": "50px" },
                                        attrs: {
                                          title: "系统使用率",
                                          value: _vm.server.cpu.sys,
                                          precision: 2,
                                          suffix: "%",
                                          "value-style": { color: "#3f8600" },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prefix",
                                              fn: function () {
                                                return [
                                                  _c("a-icon", {
                                                    attrs: {
                                                      type: "cloud-server",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          4232312562
                                        ),
                                      }),
                                      _c("a-statistic", {
                                        staticStyle: { "margin-right": "50px" },
                                        attrs: {
                                          title: "当前空闲率",
                                          value: _vm.server.cpu.free,
                                          precision: 2,
                                          suffix: "%",
                                          "value-style": { color: "#3f8600" },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "prefix",
                                              fn: function () {
                                                return [
                                                  _c("a-icon", {
                                                    attrs: { type: "inbox" },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          789749945
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "内存",
                        bordered: false,
                      },
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          loading: _vm.loading,
                          size: "small",
                          rowKey: "name",
                          columns: _vm.memColumns,
                          "data-source": _vm.memData,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "mem",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color:
                                        record.name == "使用率" && text > 80
                                          ? "red"
                                          : "",
                                    },
                                  },
                                  [
                                    record.name == "使用率" && text > 80
                                      ? _c("a-icon", {
                                          staticStyle: { color: "#FFCC00" },
                                          attrs: { type: "warning" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(text) + "   "),
                                    record.name == "使用率"
                                      ? _c("code", [_vm._v("%")])
                                      : _vm._e(),
                                    record.name != "使用率"
                                      ? _c("code", [_vm._v("G")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            },
                          },
                          {
                            key: "jvm",
                            fn: function (text, record) {
                              return _c("span", {}, [
                                _c(
                                  "div",
                                  {
                                    style: {
                                      color:
                                        record.name == "使用率" && text > 80
                                          ? "red"
                                          : "",
                                    },
                                  },
                                  [
                                    record.name == "使用率" && text > 80
                                      ? _c("a-icon", {
                                          staticStyle: { color: "#FFCC00" },
                                          attrs: { type: "warning" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(text) + "   "),
                                    record.name == "使用率"
                                      ? _c("code", [_vm._v("%")])
                                      : _vm._e(),
                                    record.name != "使用率"
                                      ? _c("code", [_vm._v("M")])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "服务器信息",
                        bordered: false,
                      },
                    },
                    [
                      _vm.server.sys
                        ? _c(
                            "a-descriptions",
                            { attrs: { column: 2 } },
                            [
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "服务器名称" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.server.sys.computerName) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "操作系统" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.sys.osName) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "服务器IP" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.server.sys.computerIp) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "系统架构" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.sys.osArch) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "Java虚拟机信息",
                        bordered: false,
                      },
                    },
                    [
                      _vm.server.jvm
                        ? _c(
                            "a-descriptions",
                            { attrs: { column: 2 } },
                            [
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "Java名称" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.jvm.name) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "Java版本" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.jvm.version) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "启动时间" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.jvm.startTime) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "运行时长" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.jvm.runTime) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "安装路径" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.jvm.home) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "a-descriptions-item",
                                { attrs: { label: "项目路径" } },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.server.sys.userDir) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      attrs: {
                        loading: _vm.loading,
                        title: "磁盘状态",
                        bordered: false,
                      },
                    },
                    [
                      _c("a-table", {
                        attrs: {
                          loading: _vm.loading,
                          size: _vm.tableSize,
                          rowKey: "dirName",
                          columns: _vm.sysColumns,
                          "data-source": _vm.sysData,
                          pagination: false,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "total",
                            fn: function (text) {
                              return _c("span", {}, [
                                _vm._v(" " + _vm._s(text) + " "),
                              ])
                            },
                          },
                          {
                            key: "free",
                            fn: function (text) {
                              return _c("span", {}, [
                                _vm._v(" " + _vm._s(text) + " "),
                              ])
                            },
                          },
                          {
                            key: "used",
                            fn: function (text) {
                              return _c("span", {}, [
                                _vm._v(" " + _vm._s(text) + " "),
                              ])
                            },
                          },
                          {
                            key: "usage",
                            fn: function (text) {
                              return _c("span", {}, [
                                _c(
                                  "div",
                                  { style: { color: text > 80 ? "red" : "" } },
                                  [
                                    text > 80
                                      ? _c("a-icon", {
                                          staticStyle: { color: "#FFCC00" },
                                          attrs: { type: "warning" },
                                        })
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(text)),
                                    _c("code", [_vm._v("%")]),
                                  ],
                                  1
                                ),
                              ])
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }